import React from 'react'
import { Link } from 'gatsby'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import { Row, Col, Button, WhatNext } from '../../components/global'
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion'
import 'react-accessible-accordion/dist/fancy-example.css'

const CircuitBoardInventoryClearance = () => (
  <Layout>
    <SEO title="Circuit Board Inventory Clearance" />
    <div className="container">
      <h1>Circuit Board Trainer Inventory Clearance</h1>
      <p className="boost">
        Last chance to purchase a complete set of 5 hands-on electrical circuit board training
        boards:
      </p>
      <p>
        We have a limited number of electrical circuit board trainers available and once these are
        sold they will no longer be available. This is your last chance to obtain a complete set of
        5 hands-on electrical training boards with work books at a 25% discount. International
        orders shipped by DHL and we will contact you by email to approve shipping cost before we
        process your order.
      </p>
      <p>
        <em>
          Each circuit board trainer comes with 1 ea. Student Workbook and 1 ea. Instructor Guide
          and its dedicated Resistor Bag.
        </em>
      </p>
      <hr />
      <h2>FINAL 3 Hands-On Electrical Training Kit Sale:</h2>
      <ul>
        <li>1ea. H-111A Starter Kit</li>
        <li>1ea. H-113 Troubleshooting DC Motor Circuits</li>
        <li>1ea. H-200 Troubleshooting CAN Bus Circuits</li>
      </ul>
      <p>
        Each trainer comes with 1 Student workbook and 1 Instructor Guide with all the answers.
        Price quoted in shopping cart $599.00 plus s/h is a 25% Discount.
      </p>
      <Row justifyContent="center">
        <Col width="50%">
          <Button
            type="secondary"
            text="BUY NOW $599.00 USD plus s/h"
            href="http://www.1shoppingcart.com/SecureCart/SecureCart.aspx?mid=2169DBD4-F088-4CA4-A011-56C1593411AC&pid=46b614a4511c4c5f82e097d7ed94f80f"
          />
        </Col>
      </Row>
      <p> </p>
      <Accordion allowZeroExpanded={true}>
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>H-111A The Starter Kit:</AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p>
              Live circuit board electrical trainers teach step-by-step electrical troubleshooting
              curriculum that shows students/technicians how to find electrical problems with a DMM.
              The Starter Kit, H-111A explains how circuits work; how to test circuits with a DMM;
              how circuits fail and how to troubleshoot any electrical circuit on any vehicle and
              find the electrical problem. The only way to learn electrical troubleshooting is by
              repetitious troubleshooting practice of a live circuit with an electrical problem
              inserted. Insert problems following instructions with answers in the Instructor Guide
              (H-IG111A) to develop electrical skill and self-confidence with hands-on practice
              troubleshooting 32 live electrical circuit problems.
            </p>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>H-113 Troubleshooting DC Motor Circuits:</AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p>
              H-113 connects to the bottom of the Power Board (H-PCB01) from the Starter Kit H-111A.
              Workbook H-WB113 has 71 pages fully illustrated with places to record all your
              troubleshooting steps. You will learn how DC motor circuits work and how a DC motor
              circuit fails. You will practice measuring voltage to find problems in DC motor
              circuits. We show you how to use an analog and digital ohmmeter to test these new
              brushless DC motors. We also show you an innovated way to use a DMM&#39;s Diode Test
              to evaluate a brushless DC motor. You will study all the ways a DC motor circuit
              fails, step-by-step, and the DMM troubleshooting techniques that reveal the problem.
              There are 37 electrical problems to practice troubleshooting, one problem at a time.
              There are 5 advanced problems, some with multiple problems which are easily found now
              that you know how to troubleshoot a circuit. You also troubleshoot short-to-ground and
              short-to-voltage problems. Never struggle with a DC motor problem again.
            </p>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>H-200 Troubleshooting CAN Bus Problems:</AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p>
              The CAN Bus trainer H-200 simulates 2 areas of CAN Bus circuit troubleshooting that
              cause major problems if overlooked.
            </p>
            <p>
              <strong>Section 1.</strong> Problems in the CAN Bus network wiring between nodes. This
              training program explains what happens when 120 ohms or 240 ohms is measured at DLC
              pins 6 and 14. Also explained is why 60 ohms at DLC pins 6 and 14 does not verify all
              Bus network wiring and what to do about it with 20 practice network wiring failure
              problems.
            </p>
            <p>
              <strong>Section 2.</strong> Problems with low B+ and poor ground circuits to nodes on
              the network are easy to identify when you know how. Explore this topic and then
              practice troubleshooting 24 individual electrical problems with low B+ or bad ground
              connections to various nodes.
            </p>
          </AccordionItemPanel>
        </AccordionItem>
      </Accordion>
      <hr />
      <h2>FAQs:</h2>
      <Accordion allowZeroExpanded={true}>
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>International Sales:</AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p>
              This close out special is available for international sales when the invoice including
              cost of shipping is paid in advance by bank transfer of funds.
            </p>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>Note to Shop Owners and Fleet Managers:</AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p>
              This is a great opportunity to obtain a comprehensive hands-on electrical-electronics
              training program to train your employees at great savings and not lose valuable shop
              time and productivity with them being away for electrical training. These circuit
              board trainers will last for years to come and will never be outdated by advancing
              vehicle technology.
            </p>
            <p>
              Technicians can study this need-to-know hands-on electrical-electronics
              troubleshooting training program individually or in small groups and discuss in shop
              meetings. Technicians can then take the circuit board trainers home to practice the
              hands-on troubleshooting exercises. As your techs absorb this electrical
              troubleshooting training you will see their electrical proficiency increase on the
              job. Technicians who participate in this training can later assist new employees
              exposed to this training as they join the shop staff.
            </p>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>
              Note to Tech Schools at Secondary or Postsecondary Level:
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p>
              This is unparalleled training to teach students the ins and outs of how to test an
              electrical-electronic circuit, how circuits fail by inserting over 300 circuit
              problems for practicing hands-on troubleshooting exercises with repetition
              troubleshooting to build self-confidence.
            </p>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>
              When All Electrical Trainer Circuit Boards Are Gone:
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p>
              After the inventory is sold off, Veejer Enterprises Inc. will continue to offer the
              workbooks, instructor guides at regular prices so that shop owners or teachers who
              invest in this close-out special offer for their students or employees will have
              on-going access for tech support and hands-on workbooks as needed.
            </p>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>Are you going out of business? (No.)</AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p>Veejer Enterprises Inc. is still in business.</p>

            <p>
              We will continue to offer repairs for circuit boards that develop problems, such as
              broken test pins or switches, most times at no charge. Just pay shipping one way. We
              will repair the circuit board and pay shipping back to you. Free circuit board repair
              is not available for international sales unless the shipping is prepaid both
              directions.
            </p>

            <p>
              We will continue selling books and training publications written by Vince Fischelli,
              such as,{' '}
              <Link to="products/shortcuts">"Vehicle Electrical Troubleshooting SHORTCUTS"</Link>{' '}
              and both laminated flip-charts,{' '}
              <Link to="products/first-things-first">
                "FIRST THINGS FIRST-Pro" and "FIRST THINGS FIRST-2."
              </Link>
            </p>

            <p>
              Other electrical training publications by Vince will be listed soon on our website and
              continued to be offered for sale.
            </p>

            <p>
              Veejer Enterprises Inc. will continue to offer <Link to="/webinars">webinars</Link> by
              Vince so be sure you are signed up for this{' '}
              <a
                href="https://visitor.r20.constantcontact.com/manage/optin?v=001Re2XAHrK4vlFTRaxxp_JhdFSCGwu-S1pEYKOLZEwrtaMpBVDAeq-THxG1RWiVwEpEk-yISZpMOQ%3D"
                target="_blank"
                rel="noopener noreferrer"
              >
                free newsletter
              </a>{' '}
              to be alerted for each new webinar announcement.
            </p>
            <p>
              You can visit our <Link to="/webinars">webinar</Link> page to purchase access to
              previous and upcoming webinars.
            </p>
          </AccordionItemPanel>
        </AccordionItem>
      </Accordion>
    </div>
    <div className="container container-last">
      <WhatNext />
    </div>
  </Layout>
)

export default CircuitBoardInventoryClearance
